<template lang="pug">
div
  template(v-if="groupIcon && weventsFiltered.length >0")
    v-row
      v-col(cols="5" class="pa-1")
        template(v-if="weventsPublish.length")
          v-tooltip(top color="white")
            template(v-slot:activator="{ on, attrs }")
              template(v-if="weventsPublishInherit.length && weventsPublishDirect.length")
                v-badge(:content="weventsPublishInherit.length" color="grey lighten-3 grey--text" offset-x="26" offset-y="40")
                  v-badge(:content="weventsPublishDirect.length" color="grey white--text" offset-x="26" offset-y="20")
                    v-btn.mr-3(depressed fab v-bind="attrs" v-on="on" small color="transparent")
                      v-icon(:color="iconPublishColor") {{ iconPublish }}
              template(v-else-if="weventsPublishInherit.length")
                v-badge(:content="weventsPublishInherit.length" color="grey lighten-3 grey--text" offset-x="26" offset-y="40")
                  v-btn.mr-3(depressed fab v-bind="attrs" v-on="on" small color="transparent")
                    v-icon(:color="iconPublishColor") {{ iconPublish }}
              template(v-else)
                v-badge(:content="weventsPublishDirect.length" color="grey white--text" offset-x="26" offset-y="20")
                  v-btn.mr-3(depressed fab v-bind="attrs" v-on="on" small color="transparent")
                    v-icon(:color="iconPublishColor" ) {{ iconPublish }}
            w-event-info-list(v-if="weventsPublishDirect.length" :zone="zone"  :weventList="weventsPublishDirect" :desc="$t('label.published')")
            w-event-info-list(v-if="weventsPublishInherit.length" :zone="zone"  :weventList="weventsPublishInherit" :desc="$t('label.published') + ' ' + $t('label.inherit')")
      v-col(cols="6" class="pa-1")
        template(v-if="weventsDraft.length")
          v-tooltip(top color="white")
            template(v-slot:activator="{ on, attrs }")
              template(v-if="weventsDraftInherit.length && weventsDraftDirect.length")
                v-badge(:content="weventsDraftInherit.length" color="grey lighten-3 grey--text" offset-x="26" offset-y="40")
                  v-badge(:content="weventsDraftDirect.length" offset-x="26" offset-y="20" color="grey white--text")
                    v-btn.mr-3(depressed fab v-bind="attrs" v-on="on" small color="transparent")
                      v-icon(:color="iconDraftColor") {{ iconDraft }}
              template(v-else-if="weventsDraftInherit.length")
                v-badge(:content="weventsDraftInherit.length" color="grey lighten-3 grey--text" offset-x="26" offset-y="40")
                  v-btn.mr-3(depressed fab v-bind="attrs" v-on="on" small color="transparent")
                    v-icon(:color="iconDraftColor") {{ iconDraft }}
              template(v-else)
                v-badge(:content="weventsDraftDirect.length" offset-x="26" offset-y="20" color="grey white--text")
                  v-btn.mr-3(depressed fab v-bind="attrs" v-on="on" small color="transparent")
                    v-icon(:color="iconDraftColor") {{ iconDraft }}
            w-event-info-list(v-if="weventsDraftDirect.length" :zone="zone" :weventList="weventsDraftDirect" :desc="$t('label.draft')")
            w-event-info-list(v-if="weventsDraftInherit.length" :zone="zone"  :weventList="weventsDraftInherit" :desc="$t('label.draft') + ' ' + $t('label.inherit')")
  span(v-else v-for="(wevent, b) in weventsFiltered" :key="b")
    wevent-icon(:wevent="wevent" xsmall)
</template>
<script>
import WeventIcon from '@/components/Castzones/WEventIcon'
import WeventInfo from '@/components/Castzones/WEventInfo'
import WEventInfoList from '@/components/Castzones/WEventInfoList'

export default {
  name: 'WeventIconList',
  components: {
    WEventInfoList,
    WeventIcon,
    WeventInfo
  },
  props: {
    zone: {
      type: Object,
      default: null
    },
    filterActive: {
      type: Boolean,
      default: true
    },
    groupIcon: {
      type: Boolean,
      default: false
    },
    iconPublish: {
      type: String,
      default: 'mdi-alpha-p-box' // 'mdi-weather-partly-snowy-rainy'
    },
    iconPublishColor: {
      type: String,
      default: 'green'
    },
    iconDraft: {
      type: String,
      default: 'mdi-alpha-b-box'
    },
    iconDraftColor: {
      type: String,
      default: 'orange darken-1'
    },
  },
  computed: {
    weventsFiltered() {
      return this.filterActive ? this.gGetWevents(this.zone).filter(w => (w.is_active && w.will_be_active == null) || (w.will_be_active) ) : this.gGetWevents(this.zone)
    },
    weventsDraftDirect() {
      return this.weventsFiltered.filter(w => w.is_draft && !w.inherit)
    },
    weventsDraftInherit() {
      return this.weventsFiltered.filter(w => w.is_draft && w.inherit)
    },
    weventsDraft() {
      return this.weventsFiltered.filter(w => w.is_draft)
    },
    weventsPublishDirect() {
      return this.weventsFiltered.filter(w => !w.is_draft && !w.inherit)
    },
    weventsPublishInherit() {
      return this.weventsFiltered.filter(w => !w.is_draft && w.inherit)
    },
    weventsPublish() {
      return this.weventsFiltered.filter(w => !w.is_draft)
    },
    colorIcon() {
      return ''
    }
  }
}
</script>
<style>
</style>
